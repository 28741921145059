type ValueOf<T> = T extends { [key: string]: infer R } ? R : never;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function forEach<T extends { [key: string]: any }>(
  value: T,
  iterator: (key: keyof T, value: ValueOf<T>) => void,
) {
  Object.keys(value).forEach((key) => {
    iterator(key as keyof T, value[key]);
  });
}
